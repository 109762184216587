import React, {useState, useEffect} from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import LoadableButton from '../../components/common/LoadableButton';
import { connect } from 'react-redux';
import { getQRcode, set2FA} from '../actions/twoFAActions';

function TwoFA(props) {
    const [confirmationCode, setConfirmationCode] = useState('');
    const [stage, setStage] = useState(0);
    const [qrURL, setQRURL] = useState('');
    useEffect(() => {
        props.getQRcode();
    }, []);

    useEffect(() => {
        if(props.status && props.status.success) {
            setConfirmationCode('');
        }

    }, [props]);

    const handleKeyDownOnInput = ev => {
        if (ev.key === "Enter") {
            props.set2FA(confirmationCode)
        }
    };

    const handleChangeConfirmationCode = (e) => {
        e.preventDefault();
        setConfirmationCode(e.target.value);
    }

    return (<div style={{width: 400, margin: 'auto'}}>
        <h3>Two-factor authentication (2FA)</h3>
        {props.enabled2FA && (<p>You have active 2FA</p>)}
        {!props.enabled2FA && (<p>Two-factor authentication adds an additional layer of security to the authentication process by making it harder for attackers to gain access to a person's devices or online accounts because, even if the victim's password is hacked, a password alone is not enough to pass the authentication check.</p>)}
        {!props.enabled2FA && (<p>To use 2FA you have to install Google Authenticator App, find it in App store or Play store.</p>)}

        {stage === 0 && !props.enabled2FA&& (<p style={{textAlign: "center"}}>You can scan QR-code</p>)}
        {stage === 0 && !props.enabled2FA&& (<p style={{textAlign: "center"}}><img src={props.url} /></p>)}
        {stage === 0 && !props.enabled2FA&& (<p style={{textAlign: "center"}}>or put this secret in your google auth app</p>)}
        {stage === 0 && !props.enabled2FA&& (<p style={{textAlign: "center"}}>{props.secret}</p>)}
        {stage === 1 && !props.enabled2FA && (<p>{props.status}</p>)}

        {stage === 1 && !props.enabled2FA && (<FormGroup>
            <FormControl
                maxLength={6}
                type="text"
                placeholder="6-number code"
                value={confirmationCode}
                onChange={handleChangeConfirmationCode}
                onKeyPress={handleKeyDownOnInput}
            />
        </FormGroup>)}

        {stage === 0 && !props.enabled2FA && (<LoadableButton
            disabled={!confirmationCode && confirmationCode.length === 6}
            block
            bsStyle="primary"
            onClick={() => setStage(1)}
        >
            Next
        </LoadableButton>)}
        {stage === 1 && !props.enabled2FA && (<LoadableButton
            disabled={!confirmationCode || confirmationCode.length !== 6}
            block
            bsStyle="primary"
            onClick={() => props.set2FA(confirmationCode)}
        >
            Save
        </LoadableButton>)}
        {stage === 1 && !props.enabled2FA && (<LoadableButton
            block
            bsStyle="danger"
            onClick={() => setStage(0)}
        >
            Go back
        </LoadableButton>)}

    </div>);
}

function mapStateToProps({twoFAReducer, auth}) {
    return {
        ...twoFAReducer,
        enabled2FA: auth && auth.user && auth.user.enabled2FA
    }
}

function mapDispatchToProps() {
    return dispatch => ({
        getQRcode: () => dispatch(getQRcode()),
        set2FA: (code) => dispatch(set2FA(code))
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFA)
