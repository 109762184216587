import { URL_CHANGED, SECRET_CHANGED, ENABLED2FA_CHANGED, STATUS_CHANGED } from "../actions/twoFAActions";

const initialState = {
    secret: null,
    url: null,
    enabled2FA: false,
    status: null
};

export default function twoFAReducer(state = initialState, action) {
    switch (action.type) {
        case URL_CHANGED:
            return {
                ...state,
                url: action.url,
            };
        case SECRET_CHANGED:
            return {
                ...state,
                secret: action.secret,
            };
        case ENABLED2FA_CHANGED:
            return {
                ...state,
                enabled2FA: action.enabled2FA,
            };
        case STATUS_CHANGED:
            return {
                ...state,
                status: action.status,
            };
        default:
            return state;
    }
}
