import React from 'react';
import IndicatorButton from "../../components/common/IndicatorButton";
import MatGrid from "../../components/common/Mat/MatGrid";
import {TimeInput} from "./TimeInput";

export default function HoursRange({ categoryIndex, groupIndex, initialIntervalFrom, initialIntervalTo, changeRangeFrom,
                                     changeRangeTo, showPlusOneDay }) {
    return (
        <>
            <MatGrid.Row>
                <MatGrid.Col>
                    <TimeInput type='time' className="form-control" step="900"
                           value={initialIntervalFrom} onChange={ev => changeRangeFrom(categoryIndex, groupIndex, ev.target.value)}
                    />
                </MatGrid.Col>
                <MatGrid.Col width="4%" justifyCenter>
                    <b style={{"display": "block", "marginLeft": "45%", "marginTop": 5}}>:</b>
                </MatGrid.Col>
                <MatGrid.Col>
                    <TimeInput type='time' className="form-control" step="900"
                           value={initialIntervalTo} onChange={ev=>changeRangeTo(categoryIndex, groupIndex, ev.target.value)}
                    />
                </MatGrid.Col>
                <MatGrid.Col width="4%" justifyCenter>
                </MatGrid.Col>
                <MatGrid.Col width="6%">
                    {showPlusOneDay && (<IndicatorButton disabled>+1d</IndicatorButton>)}
                </MatGrid.Col>

            </MatGrid.Row>
        </>
    )
}
