import React, {useEffect, useState} from 'react'
import {Button, ControlLabel, FormControl, FormGroup, NavItem} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import HoursRange from "./HoursRange";
import {clockTimeToMinutes} from "../../../../src/helpers/reportsHelpers";
import {connect} from "react-redux";
import {
    getScenarioData,
    setStartOfWeek,
    setTitle,
    changeSplitReport,
    saveScenario,
    setShortScenarioDescription,
    setScenarioDescription,
    changeScenarioWithoutRangeAdd,
    changeScenarioWithoutRange,
    changeScenarioWithoutRangeDel
} from "../actions/scenariosActions";
import {
    changeRangeName,
    changeRangeFrom,
    changeRangeTo,
    deleteCategory,
    addNewCategory,
    addNewSubcategory,
    deleteSubcategory,
    timelineHoursCategoryChangeCheckbox,
    changeName
} from "../actions/hoursRangeActions";
import SaveModalContainer from "../utils/SaveModalContainer";
import MatButton from "../../components/common/Mat/MatButton";
import MatGrid from "../../components/common/Mat/MatGrid";
import MatHintText from "../../components/common/Mat/MatHintText";
import StyledIcon from "../../components/common/StyledIcon";
import MatBox from "../../components/common/Mat/MatBox";
import MatLabel from "../../components/common/Mat/MatLabel";
import MatInput from "../../components/common/Mat/MatInput";
import IconButton from "../../components/common/IconButton";
import StyledButton from "../../components/common/StyledButton";
import {TimeInput} from "./TimeInput";

function MainScenario({ saveScenario, getScenarioData, setStartOfWeekData, setTitle, changeNameData, changeRangeNameData,
  changeRangeFromData, changeRangeToData, match, deleteCategory, addNewCategory, addNewSubcategoryData, deleteSubcategoryData,
  timelineHoursCategoryChangeCheckboxData, changeSplitReportData, title, categories,
  splitReport, startOfWeekHour, isLoading, shortDescription, setShortScenarioDescriptionData,
  description, setScenarioDescriptionData, scenariosWithoutRange, changeScenarioWithoutRangeData, changeScenarioWithoutRangeDelData, changeScenarioWithoutRangeAddOne
}) {
    useEffect(() => {
        if (match.params.id) {
            getScenarioData(match.params.id);
        }
    }, [match]);

    const [showModal, setShowModal] = useState(false);
    const [modalVersionMessage, setModalVersionMessage] = useState("");
    const weekDaysName = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Holiday"]

    return (<div style={{justifyContent: 'center'}}>
        <MatBox>
            <MatGrid.Row wrapMobile>
                <MatGrid.Col width="100%" mobileWidth="100%">
                    <MatGrid.Row>
                        <MatGrid.Col width="50%" style={{"marginBottom": -15, "marginTop": 15}}>
                            <MatInput
                                label="Scenario's name"
                                type="text"
                                value={title}
                                onChange={event => {
                                    setTitle(event.target.value)}}
                            />
                        </MatGrid.Col>
                    </MatGrid.Row>
                    <MatGrid.Row>
                        <MatGrid.Col width="100%">
                            <FormGroup>
                                <ControlLabel title="It is basic information about scenario, this information are showing in order's page.">Description:</ControlLabel>
                                <FormControl
                                    style={{height: 35}}
                                    componentClass="textarea"
                                    value={description}
                                    onChange={(event) => {
                                        setScenarioDescriptionData(event.target.value)
                                    }}/>
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel title="This value are showing in report as information if reporter create data in more that one period of times - read more info in 'Time range closed' checkbox">Reason of invalid time range:</ControlLabel>
                                <FormControl
                                    style={{height: 35}}
                                    componentClass="textarea"
                                    value={shortDescription}
                                    onChange={(event) => {
                                        setShortScenarioDescriptionData(event.target.value)
                                    }}/>
                            </FormGroup>
                        </MatGrid.Col>
                    </MatGrid.Row>
                    <MatGrid.Row>
                        <MatGrid.Col>
                            <b style={{"marginTop": 4, "display": "block", "marginRight": 10}} title="This value is used by reports, balloon for showing information about '+1d'. The value is important for calculation ranges for calculation working periods. Ex: time before this values are calculating as one day before. If you set 6:00 hours on monday between 0:00-6:00 are calculated as sunday's hours.">Start of the day </b>
                        </MatGrid.Col>
                        <MatGrid.Col>
                            <TimeInput type='time' className="form-control" step="900" onChange={event => {
                                setStartOfWeekData(event.target.value)
                                }} value={startOfWeekHour}/>
                        </MatGrid.Col>
                        <MatGrid.Col>
                            <b style={{"marginTop": 4, "display": "block", "marginRight": 10, "marginLeft": 80}} title="If you set this field to true, all reports must be created according to the rule that splits reports without exception. For example, if you have two categories of hours: normal (6-22) and night (22-6), you must save hours in one of these categories by report. You cannot save a period from 5:00 to 7:00 because it spans two categories. The correct way is to create two reports: one for the period from 5:00 to 6:00, and another for the period from 6:00 to 7:00.">Time range closed </b>
                        </MatGrid.Col>
                        <MatGrid.Col>
                            <input
                                type="checkbox"
                                className="checkbox-big"
                                checked={splitReport}
                                onChange={ev => {
                                    changeSplitReportData(ev.target.checked)
                                }}
                            />
                        </MatGrid.Col>
                    </MatGrid.Row>
                </MatGrid.Col>
            </MatGrid.Row>
        </MatBox>
        {!isLoading && (
            <div>
                <div style={{ display: 'block' }}>
                    {match.params.id &&
                        (<div>
                            {categories && categories.map((category, categoryIndex) =>
                                <MatBox>
                                    <MatGrid.Row>
                                        <MatGrid.Col width="10%" style={{minWidth: 65}}>
                                            <b title="Name of category will be showing in customer panel.">Category</b>
                                        </MatGrid.Col>
                                        <MatGrid.Col width="85%">
                                            <input key={`CategoryName${categoryIndex}`} value={category.category}
                                                   onChange={ev => {
                                                       changeNameData(categoryIndex, ev.target.value)
                                                   }}/>
                                        </MatGrid.Col>
                                        <MatGrid.Col width="5%">
                                            <IconButton
                                                title="Delete category"
                                                glyph="trash"
                                                bsStyle="danger"
                                                onClick={() => {
                                                    deleteCategory(categoryIndex)
                                                }}
                                            />
                                        </MatGrid.Col>
                                    </MatGrid.Row>

                                    {category.grouped && category.grouped.map((group, groupIndex) =>
                                        <>
                                            <MatGrid.Row style={{"marginTop": 8}}>
                                                <MatGrid.Col width="10%" style={{minWidth: 65}}><b style={{"display": "block", "marginTop": 10}} title="Set days when this rane should be use to create calculation periods. You can also set 'holiday' - this is special day who creates special periods of time for calculation working hours for holidays. You can set holidays in calendar.">Days</b></MatGrid.Col>
                                                <MatGrid.Col width="90%">{group.checkedDays.map((el, dayIndex) =>
                                                    <div style={{display: "inline-block", padding: 10}}>{weekDaysName[dayIndex]}
                                                        <input style={{"marginLeft": 8}}
                                                            type="checkbox"
                                                            checked={el}
                                                            onChange={(ev) => {
                                                               timelineHoursCategoryChangeCheckboxData(categoryIndex, groupIndex, dayIndex, ev.target.checked)
                                                            }}/>
                                                    </div>
                                                )}</MatGrid.Col>

                                            </MatGrid.Row>
                                            <MatGrid.Row style={{"marginTop": 8}}>
                                                <MatGrid.Col width="10%" style={{minWidth: 65}}>
                                                    <b style={{"display": "block", "marginTop": 5}} title="Hours 'from-to' defined here will generate working hour ranges for the categories in order to calculate hours worked for pre externali.">Range</b>
                                                </MatGrid.Col>
                                                <MatGrid.Col width="85%">
                                                    <HoursRange
                                                        initialIntervalFrom={group.initialIntervalFrom}
                                                        initialIntervalTo={group.initialIntervalTo}
                                                        key={`hoursRange${groupIndex}${groupIndex}`}
                                                        changeName={changeNameData}
                                                        changeRangeFrom={changeRangeFromData}
                                                        changeRangeTo={changeRangeToData}
                                                        groupIndex={groupIndex}
                                                        categoryIndex={categoryIndex}
                                                        showPlusOneDay={(group.initialIntervalFrom && group.initialIntervalTo && ((clockTimeToMinutes(group.initialIntervalFrom) > clockTimeToMinutes(group.initialIntervalTo) && clockTimeToMinutes(group.initialIntervalTo) !== 0) || (clockTimeToMinutes(group.initialIntervalFrom) === clockTimeToMinutes(group.initialIntervalTo) && clockTimeToMinutes(group.initialIntervalTo) !== 0)))}
                                                    />
                                                </MatGrid.Col>
                                                <MatGrid.Col width="5%">
                                                    <IconButton
                                                        title="Delete subcategory"
                                                        glyph="trash"
                                                        bsStyle="danger"
                                                        onClick={() => {
                                                            deleteSubcategoryData(categoryIndex, groupIndex)
                                                        }}
                                                    />
                                                </MatGrid.Col>
                                            </MatGrid.Row>
                                            <hr />
                                        </>
                                    )}
                                        <StyledButton wide onClick={() => {
                                            addNewSubcategoryData(categoryIndex)
                                        }}>
                                            <StyledIcon glyph="plus" />
                                        </StyledButton>
                                </MatBox>
                            )}
                            {scenariosWithoutRange && scenariosWithoutRange.length > 0 && (<MatBox>
                                {scenariosWithoutRange.map((el, index) => {
                                    return (
                                        <MatGrid.Row style={{height: 40}}>
                                            <MatGrid.Col width="15%">
                                                <b title="This fields are showing in pre-external as special fields to add to external. Default value is always 0, user who create external can add count of hours for special category - ex. If with controllers worked leader you can add his hours count to external. Name of this category will be showing in customer panel.">Category without range:</b>
                                            </MatGrid.Col>
                                            <MatGrid.Col width="80%">
                                                <input key={`scenariosWithoutRange-${index}`} value={el}
                                                       onChange={ev => {changeScenarioWithoutRangeData(ev.target.value, index)}}/>
                                            </MatGrid.Col>
                                            <MatGrid.Col width="5%">
                                                <IconButton
                                                    glyph="trash"
                                                    bsStyle="danger"
                                                    onClick={()=> changeScenarioWithoutRangeDelData(index)}
                                                />
                                            </MatGrid.Col>
                                        </MatGrid.Row>
                                    )
                                })}
                            </MatBox>)}
                            <MatBox>
                                <MatGrid.Row>
                                    <MatGrid.Col>
                                        <span>You create the scenario at your own risk. Remember to add categories for all days and hours of the week and holidays. If you forget, they won't count. Also remember not to overlap hours as they will be counted twice.</span>
                                    </MatGrid.Col>
                                </MatGrid.Row>
                            </MatBox>
                            <StyledButton wide onClick={addNewCategory} style={{marginRight: 35}}>
                                Add category
                            </StyledButton>
                            <StyledButton wide onClick={() => changeScenarioWithoutRangeAddOne()}
                                >Add special category
                            </StyledButton>

                            <div style={{ display: 'block', marginTop: 10 }}>
                                <LinkContainer to="/dashboard/scenario">
                                    <MatButton danger>Back</MatButton>
                                </LinkContainer>
                                <Button
                                    className="pull-right"
                                    bsStyle="primary"
                                    onClick={() => setShowModal(!showModal)}
                                >Save</Button>
                            </div>
                        </div>)
                    }
                </div>
                <SaveModalContainer
                    showVersionMessageModal={showModal}
                    closeModal={() => setShowModal(false)}
                    onSave={() => saveScenario(match.params.id, modalVersionMessage)}
                    modalVersionMessage={modalVersionMessage}
                    setModalVersionMessage={setModalVersionMessage}
                    title="scenario"
                />
        </div>)}
    </div>)
}

const mapStateToProps = ({scenariosReducer, hoursRangeReducer}) => {
    return {
        ...scenariosReducer,
        ...hoursRangeReducer
    }
}

function mapDispatchToProps() {
    return dispatch => ({
        saveScenario: (id, versionMessage) => dispatch(saveScenario(id, versionMessage)),
        getScenarioData: (id) => dispatch(getScenarioData(id)),
        setStartOfWeekData: (value) => dispatch(setStartOfWeek(value)),
        setTitle: (value) => dispatch(setTitle(value)),
        changeNameData: (categoryIndex, value) => dispatch(changeName(categoryIndex, value)),
        changeRangeNameData: (categoryIndex, rangeIndex, value) => dispatch(changeRangeName(categoryIndex, rangeIndex, value)),
        changeRangeFromData: (categoryIndex, groupIndex, value) => dispatch(changeRangeFrom(categoryIndex, groupIndex, value)),
        changeRangeToData: (weekIndex, rangeIndex, value) => dispatch(changeRangeTo(weekIndex, rangeIndex, value)),
        deleteCategory: (index) => dispatch(deleteCategory(index)),
        addNewCategory: () => dispatch(addNewCategory()),
        addNewSubcategoryData: (categoryIndex) => dispatch(addNewSubcategory(categoryIndex)),
        deleteSubcategoryData: (categoryIndex, groupIndex) => dispatch(deleteSubcategory(categoryIndex, groupIndex)),
        timelineHoursCategoryChangeCheckboxData: (categoryIndex, groupIndex, dayIndex, value) => dispatch(timelineHoursCategoryChangeCheckbox(categoryIndex, groupIndex, dayIndex, value)),
        changeSplitReportData: (value) => dispatch(changeSplitReport(value)),
        setShortScenarioDescriptionData: (value) => dispatch(setShortScenarioDescription(value)),
        setScenarioDescriptionData: (value) => dispatch(setScenarioDescription(value)),
        changeScenarioWithoutRangeAddOne: () => dispatch(changeScenarioWithoutRangeAdd("new")),
        changeScenarioWithoutRangeData: (value, index) => dispatch(changeScenarioWithoutRange(value, index)),
        changeScenarioWithoutRangeDelData: (index) => dispatch(changeScenarioWithoutRangeDel(index))

    })
}
export default connect(mapStateToProps, mapDispatchToProps)(MainScenario);
